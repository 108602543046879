import React, { useContext } from "react";
import { useState } from "react";
import { timeToSeconds } from "modules/website/helpers/TimeFunctions";

const GlifosRichMediaContext = React.createContext();
const GlifosRichMediaUpdateContext = React.createContext();
const GlifosPlayerContext = React.createContext();

export function useGlifosPlayerData() {
  return useContext(GlifosRichMediaContext);
}
export function useGlifosPlayerUpdate() {
  return useContext(GlifosRichMediaUpdateContext);
}
export function useGlifosPlayerRef() {
  return useContext(GlifosPlayerContext);
}

export default function GlifosRichMediaProvider({ children }) {
  const [glifosPlayerRef, setGlifosPlayerRef] = useState(null);
  const [glifosPlayerData, setGlifosPlayerData] = useState({
    ref: null,
    src: "",
    type: "",
    videoLength: 0,
    currentPosition: 0,
    seekThumbNailSrc: "",
    videoInPIP: false,
    posterUrl: "",
    volume: 0.75,
    previousVolume: 0.75,
    playBackRate: 1.0,
    progressInterval: 1,
    stopOnUnmount: true,
    ready: false,
    status: "paused",
    playing: false,
    buffering: false,
    onFullScreen: false,
    markTime: null,
    playerWith: 0,
    playerHeight: 0,
  });

  function updateGlifosPlayerData(data) {
    setGlifosPlayerData((prevData) => ({ ...prevData, ...data }));
  }
  function commandToPlayer({ type, value }) {
    if (glifosPlayerRef !== null) {
      switch (type) {
        case "seek": {
          glifosPlayerRef.seekTo(timeToSeconds(value));
          break;
        }
        case "play": {
          //console.log("Comando play...",glifosPlayerData.playing);
          setGlifosPlayerData((prevData) => ({
            ...prevData,
            playing: !glifosPlayerData.playing,
          }));
          break;
        }
        case "mute": {
          setGlifosPlayerData((prevData) => ({
            ...prevData,
            previousVolume:
              glifosPlayerData.volume > 0
                ? glifosPlayerData.volume
                : glifosPlayerData.previousVolume,
            volume:
              glifosPlayerData.volume > 0 ? 0 : glifosPlayerData.previousVolume,
          }));
          break;
        }
        case "pip": {
          //console.log("comando pip",!glifosPlayerData.pipEnabled);
          setGlifosPlayerData((prevData) => ({
            ...prevData,
            pipEnabled: !glifosPlayerData.pipEnabled,
          }));
          break;
        }
        case "pause": {
          setGlifosPlayerData((prevData) => ({ ...prevData, playing: false }));
          break;
        }
        case "test": {
          glifosPlayerRef.seekTo(timeToSeconds(value));
          /* setTimeout(() => {
                        if (!glifosPlayerData.playing) {
                            setGlifosPlayerData(prevData => ({ ...prevData, playing: !glifosPlayerData.playing }));
                        }
                    }, 5000); */

          //setGlifosPlayerData(prevData=>({...prevData, playing:!glifosPlayerData.playing}));
          break;
        }
      }
    } else {
      console.log("player es null");
    }
  }
  function setRefToPlayer(playerO) {
    setGlifosPlayerRef(playerO);
  }
  return (
    <GlifosRichMediaContext.Provider value={glifosPlayerData}>
      <GlifosRichMediaUpdateContext.Provider
        value={{ update: updateGlifosPlayerData, command: commandToPlayer }}
      >
        <GlifosPlayerContext.Provider
          value={{ setUp: setRefToPlayer, player: glifosPlayerRef }}
        >
          {children}
        </GlifosPlayerContext.Provider>
      </GlifosRichMediaUpdateContext.Provider>
    </GlifosRichMediaContext.Provider>
  );
}
