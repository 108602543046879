import React from "react";
import { motion } from "framer-motion";

const AnimatedPlatform = (props) => {
  const { text } = props;
  return (
    <motion.h4
      initial={{ scale: 0.2, opacity: 0 }}
      animate={{ scale: [0.2, 0.2, 1, 1], opacity: [0, 0, 1, 1, 0] }}
      transition={{
        scale: {
          duration: 11,
          repeat: Infinity,
          times: [0, 0.52, 0.57, 1],
        },
        opacity: {
          times: [0, 0.7, 0.75, 0.95, 0.96], // Sincroniza los tiempos con la animación de 'y'
          duration: 11,
          repeat: Infinity,
        },
      }}
      className="text-center text-white"
    >
      {text}
    </motion.h4>
  );
};

export default AnimatedPlatform;
