import React from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./NotFound.module.scss";
import AnimatedText from "Site/components/AnimatedText/AnimatedText";

const NotFound = () => {
  return (
    <Container fluid>
      <Row className={`${styles.rowContainer}`}>
        <div
          className={`col-6 align-items-center justify-content-center bg-dark d-none d-md-flex ${styles.imageBackground} px-0 text-white`}
        >
          <div className={`${styles.animatedText}`}>
            <AnimatedText
              width="100"
              text={"Building Peace through education and community engagement"}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
          <div className="">
            <h4>Page Not Found</h4>
            <p>Sorry, the page you are looking for cannot be found.</p>
            <p>
              Please go back to the <a href="/">homepage</a>.
            </p>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default NotFound;
