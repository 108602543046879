import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AnimatedLetter from "./AnimatedLetter/AnimatedLetter";

const AnimatedText = ({ text }) => {
  const [key, setKey] = useState(0);
  const [isLoaded, setIsLoaded] = useState(null);
  const [breaks, setBreaks] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 11000);

    return () => clearInterval(interval);
  }, []);
  // TODO revisar este metodo
  useEffect(() => {
    if (isLoaded === null) {
      const container = document.getElementById("animatedContainer");
      var lastWidth = 0;
      const breaksArray = [];
      let lastBreak = 0;
      for (let index = 0; index < text.split("").length; index++) {
        const letter = text.split("")[index];
        lastWidth += 30.69;
        if (letter === " ") {
          lastBreak = index;
        }
        if (lastWidth >= container.offsetWidth) {
          breaksArray.push(lastBreak);
          lastWidth = (index - lastBreak) * 30.69;
        }
      }
      setBreaks(breaksArray);
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        id="animatedContainer"
        key={key}
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 1, 1, 0] }}
        transition={{
          opacity: {
            times: [0, 0.15, 0.98, 0.99], // Sincroniza los tiempos con la animación de 'y'
            duration: 11,
          },
        }}
        style={{ fontSize: "46px" }}
      >
        {isLoaded === true && (
          <>
            {text.split("").map((letter, index) => {
              return (
                <AnimatedLetter
                  key={index}
                  index={index}
                  letter={letter}
                  split={breaks.includes(index)}
                />
              );
            })}
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimatedText;
