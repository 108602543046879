// FullPageSpinner.js
import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./FullPageSpinner.module.scss";

const FullPageSpinner = () => {
  return (
    <div className={styles.overlay}>
      <Spinner animation="border" role="status" className={styles.spinner}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default FullPageSpinner;
