import React from "react";
import AboutLinkButton from "./AboutLinkButton/AboutLinkButton";

export default function AboutSection(props) {
  const { payload } = props;
  return (
    <div className="text_jutify">
      <h4 className="text-start">
        {payload?.field_mp_about_udp_label?.[0]?.value || ""}
      </h4>
      {payload?.field_mp_about_udp_description?.[0]?.format === "basic_html" ? (
        <div
          dangerouslySetInnerHTML={{
            __html: payload?.field_mp_about_udp_description?.[0]?.value,
          }}
        />
      ) : (
        <p>{payload?.field_mp_about_udp_description?.[0]?.value}</p>
      )}
      <div className="d-block">
        {payload?.field_mp_about_udp_list?.map((element, idx) => {
          return <AboutLinkButton {...element} key={idx} />;
        })}
      </div>
    </div>
  );
}
