import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import AppRouter from "routes/AppRouter";
import { Provider } from "react-redux";
import { store } from "services/redux/store";
import "styles/theme.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Create a client
root.render(
  <Provider store={store}>
      <AppRouter />
    {/* <React.StrictMode>
    </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
