import { createSlice } from "@reduxjs/toolkit";

export const LANGUAJE_MAPS = {
  en: "",
  rw: "rw",
};

export const initialState = {
  isLoading: false,
  isLoaded: false,
  languaje: LANGUAJE_MAPS["en"],
  userfulLinks: [],
};

const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    failLoaded: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    loadMenu: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.languaje = action.payload?.languaje;
      state.menu = action.payload?.menu;
      state.userfulLinks = action.payload?.userfulLinks;
    },
  },
});

export const { startLoading, endLoading, failLoaded, loadMenu } =
  siteSlice.actions;
export default siteSlice.reducer;
