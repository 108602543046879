// import CentralElementLayout from "common/layouts/CentralElementLayout/CentralElementLayout";
import SideMenuTopBarLayout from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout";
import AliasNodeView from "modules/website/views/AliasNodeView/AliasNodeView";
import HomePage from "modules/website/views/HomePage/HomePage";
import { Navigate } from "react-router-dom";
import NotFound from "views/NotFound/NotFound";

const DefaultRoutes = [
  {
    path: `/`,
    element: (
      <SideMenuTopBarLayout variant="dark">
        <HomePage />
      </SideMenuTopBarLayout>
    ),
  },
  {
    path: `/node/:id`,
    element: (
      <SideMenuTopBarLayout variant="alternative">
        <AliasNodeView />
      </SideMenuTopBarLayout>
    ),
  },
  {
    path: `*`,
    element: <Navigate to="/not-found" replace />,
  },
  {
    path: `/not-found`,
    element: <NotFound />,
  },
];

export default DefaultRoutes;
