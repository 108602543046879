import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./SideMenuTopBarLayout.module.scss";
import Footer from "./Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import FullPageSpinner from "common/components/FullPageSpinner/FullPageSpinner";
import { siteLoadMenu } from "services/redux/site/siteActions";
import { useLocation } from "react-router-dom";
import { LANGUAJE_MAPS } from "services/redux/site/SiteSlice";
import SideMenuTopBar from "./SideMenuTopBar/SideMenuTopBar";

export default function SideMenuTopBarLayout(props) {
  const { className, variant = "dark" } = props;
  const dispatch = useDispatch();
  const site = useSelector((state) => state.site);
  const location = useLocation();

  useEffect(() => {
    const loadMenuOptions = async () => {
      const queryParams = new URLSearchParams(location.search);
      const lang = queryParams.get("lang");
      const languaje = LANGUAJE_MAPS?.[lang] !== undefined ? lang : "en"; // * ENGLISH DEFAULT
      await dispatch(siteLoadMenu(languaje));
    };
    if (!site?.isLoaded) {
      loadMenuOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY || window.pageYOffset;
    if (scrollPosition > 160) {
      const scrollSections = document.querySelectorAll(".scrollSection");
      scrollSections.forEach((section) => {
        section.classList.remove("scrollSection");
        section.classList.add("scrollSectionHiden");
      });
    }
    // else if (scrollPosition < 70) {
    //   const scrollSections = document.querySelectorAll(".scrollSectionHiden");
    //   scrollSections.forEach((section) => {
    //     section.classList.remove("scrollSectionHiden");
    //     section.classList.add("scrollSection");
    //   });
    // }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container
      className={`sm-layout-wrapper ${className} g-0 ${styles.container}`}
      fluid
    >
      {site?.isLoading && <FullPageSpinner />}
      {site?.error && <h1>{site?.error}</h1>}
      {site?.isLoaded && (
        <>
          <Row className={"g-0"}>
            <Col xs={true} className={styles.pagecontentcolumn}>
              <div className={`scrollSection`}>
                <SideMenuTopBar variant={variant} />
              </div>
              <div className={styles.pagecontentwrapper}>{props.children}</div>
            </Col>
          </Row>
          <Footer />
        </>
      )}
    </Container>
  );
}
