import React from "react";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./AboutLinkButton.module.scss";

export default function AboutLinkButton(props) {
  const { title, uri } = props;

  const getUri = (uri) => {
    if (!uri) return null;
    return uri?.replace("internal:", "");
  };

  return (
    <div className="mb-2 fill_md_btn flex_sm_column align_items_sm_start">
      <Link to={getUri(uri) || "#"} target="_blank" rel="noreferrer">
        <Button
          variant="warning text-white text-start"
          className={`${styles.about_btn}`}
        >
          <FaArrowRight /> {title}
        </Button>
      </Link>
    </div>
  );
}
