import React from "react";
import { Button, Card } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./ToolKitElement.module.scss";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import { extractSimpleNodeValue } from "common/helpers/DataFormat";
import { useSelector } from "react-redux";
import { useNodePage } from "modules/website/hooks/useNodePage";
import { LANGUAJE_MAPS } from "services/redux/site/SiteSlice";
import ImageWithFallback from "common/components/ImageWithFallback/ImageWithFallback";
import falback from "assets/img/fallbackImage.jpg";
import { gT } from "translate/translator";

export default function ToolKitElement(props) {
  const site = useSelector((state) => state.site);
  const { isLoading, isError, payload, error } = useNodePage({
    nid: props?.target_id,
    languaje: LANGUAJE_MAPS[site?.languaje],
  });

  const getUri = (uri) => {
    if (!uri) return null;
    return uri?.replace("internal:", "");
  };

  return (
    <Card className={`${styles.toolkit_card}`}>
      {isLoading && <LoadingBox />}
      {!isLoading && isError && <p>{error}</p>}
      {!isError && !isError && (
        <>
          {/* <Card.Img variant="top" src={falback} className={styles.title} /> */}
          <ImageWithFallback
            variant="top"
            className={`card-img-top`}
            fallback={falback}
            src={payload?.field_toolkit_main_image?.[0]?.url}
          />
          <Card.Body>
            <Card.Title className="text-center">
              {extractSimpleNodeValue(payload, "title")}
            </Card.Title>
            <Card.Text className="text_justify">
              {extractSimpleNodeValue(payload, "field_toolkit_description")}
            </Card.Text>
            <div className="text-center fill_md_btn">
              <Link
                to={getUri(payload?.field_toolkit_link?.[0]?.uri) || "#"}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="success" className="text-white">
                  <FaArrowRight />{" "}
                  {payload?.field_toolkit_link?.[0]?.title ||
                    gT(site.languaje, "learnmore")}
                </Button>
              </Link>
            </div>
          </Card.Body>
        </>
      )}
    </Card>
  );
}
