import { useEffect, useState } from "react";
import apiService from "services/BackendAPI/BackendAPI";

export function useNodeMetadata(props) {
  const { nid, stylesheet = "xml2Json" } = props;
  const [pageContent, setPageContent] = useState({
    isLoading: false,
    isError: false,
    payload: null,
    error: null,
  });

  useEffect(() => {
    setPageContent((prevPageContent) => ({
      ...prevPageContent,
      isLoading: true,
    }));
    apiService
      .GetNodeMetadata({
        urlParams: {
          nid: nid,
          stylesheet: stylesheet,
        },
      })
      .then((response) => {
        setPageContent((prevPageContent) => ({
          ...prevPageContent,
          isError: false,
          payload: {
            ...response?.data,
          },
        }));
      })
      .catch((error) => {
        setPageContent((prevPageContent) => ({
          ...prevPageContent,
          isError: true,
          error: error?.message,
        }));
      })
      .finally(() => {
        setPageContent((prevPageContent) => ({
          ...prevPageContent,
          isLoading: false,
        }));
      });
  }, [nid, stylesheet]);

  return pageContent;
}
