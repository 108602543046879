import axios from 'axios';
import BackendApiURLs from './BackendAPIURLs';

class Service {
	constructor() {
		let headers = {};
		let userLocal = localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null;

		if (userLocal && userLocal.access_token) {
			headers.Authorization = `Bearer ${userLocal.access_token}`;
		}
		let service = axios.create({
			baseURL: BackendApiURLs.baseURL,
			headers: headers,
		});

		Object.keys(BackendApiURLs).forEach((element) => {
			if ('baseURL' !== element) {
				Service.prototype[element] = function (payload) {
					let validPayload = true;
					if (undefined !== BackendApiURLs[element].requires)
						// Body params Required
						BackendApiURLs[element].requires.forEach(
							(elementPayload) => {
								validPayload =
									validPayload &&
									payload.hasOwnProperty(elementPayload);
							}
						);
					if (undefined !== BackendApiURLs[element].urlParams)
						// URL Parameters Required
						BackendApiURLs[element].urlParams.forEach(
							(elementPayload) => {
								validPayload =
									validPayload &&
									payload.urlParams?.hasOwnProperty(
										elementPayload
									);
							}
						);
					if (undefined !== BackendApiURLs[element].params)
						// URL Parameters Required
						BackendApiURLs[element].params.forEach(
							(elementPayload) => {
								validPayload =
									validPayload &&
									payload.params?.hasOwnProperty(
										elementPayload
									);
							}
						);
					if (!validPayload)
						return Promise.reject({
							message: 'Not a valid request',
							error: true,
						});

					return this[BackendApiURLs[element].method](
						BackendApiURLs[element].href,
						payload
					);
				};
			}
		});

		this.headers = headers;
		this.service = service;
		this.service.interceptors.response.use(
			this.handleSuccess,
			this.handleError
		);

		this.service.interceptors.request.use(this.handleRequest, (error) => {
			return Promise.reject(error);
		});
	}

	handleRequest(config) {
		if (!config.url) {
			return config;
		}
		const currentUrl = new URL(config.url, config.baseURL);
		// parse pathName to implement variables
		Object.entries(config.urlParams || {}).forEach(([key, value]) => {
			currentUrl.pathname = currentUrl.pathname.replace(
				`:${key}`,
				encodeURIComponent(value)
			);
		});

		return {
			...config,
			url: currentUrl.pathname,
		};
	}
	resetService() {
		let headers = {};

		let userLocal = localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null;
		//console.log("Service Constructor");

		if (userLocal && userLocal.access_token) {
			headers.Authorization = `Bearer ${userLocal.access_token}`;
			//console.log("Headers on constructor:",headers);
		}
		let service = axios.create({
			baseURL: BackendApiURLs.baseURL,
			headers: headers,
		});

		Object.keys(BackendApiURLs).forEach((element) => {
			if ('baseURL' !== element) {
				Service.prototype[element] = function (payload) {
					let validPayload = true;
					if (undefined !== BackendApiURLs[element].requires)
						// Body params Required
						BackendApiURLs[element].requires.forEach(
							(elementPayload) => {
								validPayload =
									validPayload &&
									payload.hasOwnProperty(elementPayload);
							}
						);
					if (undefined !== BackendApiURLs[element].urlParams)
						// URL Parameters Required
						BackendApiURLs[element].urlParams.forEach(
							(elementPayload) => {
								validPayload =
									validPayload &&
									payload.urlParams?.hasOwnProperty(
										elementPayload
									);
							}
						);
					if (undefined !== BackendApiURLs[element].params)
						// URL Parameters Required
						BackendApiURLs[element].params.forEach(
							(elementPayload) => {
								validPayload =
									validPayload &&
									payload.params?.hasOwnProperty(
										elementPayload
									);
							}
						);
					if (!validPayload)
						return Promise.reject({
							message: 'Not a valid request',
							error: true,
						});
					return this[BackendApiURLs[element].method](
						BackendApiURLs[element].href,
						payload
					);
				};
			}
		});

		this.headers = headers;
		this.service = service;
		this.service.interceptors.response.use(
			this.handleSuccess,
			this.handleError
		);

		this.service.interceptors.request.use(this.handleRequest, (error) => {
			return Promise.reject(error);
		});
	}
	checkAuth() {
		if (!this.headers.Authorization) {
			if (localStorage.userInfo && localStorage.access_token) {
				this.headers.Authorization = `Bearer ${localStorage.userInfo.access_token}`;
			}
		}
	}

	handleSuccess(response) {
		return response;
	}

	handleError = (error) => {
		if (error.response) {
			// Request made and server responded

			switch (error.response.status) {
				case 401:
					localStorage.removeItem('userInfo');
					this.resetService();
					if (!(error.config.url.indexOf('/auth/authenticate') >= 0))
						window.location.href =
							'/login?returnTo=' +
							encodeURIComponent(window.location.href);
					break;
				case 404:
					this.redirectTo(document, '/404')
					break;
				default:
					break;
			}
		} else if (error.request) {
			console.log(error.request);
		} else {
			console.log('Error', error.message);
		}
		return Promise.reject(error);
	};

	redirectTo = (document, path) => {
		document.location = path;
	};

	get(path, params, callback) {
		return this.service.get(path, params);
	}

	patch(path, payload, callback) {
		return this.service.request({
			method: 'PATCH',
			url: path,
			responseType: 'json',
			data: payload,
		}); //.then((response) => callback(response.status, response.data));
	}

	post(path, payload, callback) {
		return this.service.request({
			method: 'POST',
			url: path,
			responseType: 'json',
			data: payload,
		});
	}
}
const service = new Service();
export default service;
