export const stories = [
    {
        title: "One Connection Association",
        content: 'Dieudonné is a youth champion from Rubavu District, Western Province. Before being trained by Aegis Trust on Peacebuilding, he was a drug addict. He has now founded a peace club in his community called "One Direction Association".',
        image: "https://www.ubumuntu.rw/sites/default/files/styles/main_image_peace_clubs_community/public/2019-07/one-connection.jpg?itok=M20aUfmV",
        url: "https://ubumuntu.rw/node/294#n-1"
    },
    {
        title: "A Genuine Change",
        content: 'Peace and Values Education was chosen due to the importance of cultivating positive attitudes and values among Rwandans, including personal responsibility, pluralism, empathy, critical thinking, and action in order to build social cohesion.',
        image: "https://www.ubumuntu.rw/sites/default/files/styles/main_image_peace_clubs_community/public/2019-07/genuine-change.jpg?itok=H6GwM9tt",
        url: "https://ubumuntu.rw/node/295#n-1"
    },
    {
        title: "Healing Journey",
        content: "When people don't come together and talk about their wounds, they can’t be healed. It is a story about how Genocide survivors and Genocide perpetrators and their children had a tough time living again together. IRDP brought them together and helped them heal and allow them to live in harmony.",
        image: "https://www.ubumuntu.rw/sites/default/files/styles/main_image_peace_clubs_community/public/2019-07/healing-journey.jpg?itok=7d9tnpsv",
        url: "https://ubumuntu.rw/node/296#n-1"
    },
    {
        title: "Dream to a brighter future",
        content: 'One Help One Direction Ihumure(OHODI) is an association founded by Amani Pacifique a youth champion trained on Peace and Values Education.',
        image: "https://www.ubumuntu.rw/sites/default/files/styles/main_image_peace_clubs_community/public/2019-07/dream-to-brighter-future.jpg?itok=YgUgS5tg",
        url: "https://ubumuntu.rw/node/272"
    },
    {
        title: "Inspiring for change",
        content: 'Fiston Gaspard is a youth champion trained on Peace and Values Education. This story showcase how he used lessons learnt from the training on empathy, leadership, conflicts resolution and solidarity, to build a better society in his community.',
        image: "https://www.ubumuntu.rw/sites/default/files/styles/main_image_peace_clubs_community/public/2019-07/inspiring-for-change.jpg?itok=lqY0_iX9",
        url: "https://ubumuntu.rw/node/298#n-1"
    },
]