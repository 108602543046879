import { startLoading, failLoaded, loadMenu, LANGUAJE_MAPS } from "./SiteSlice";
import apiService from "services/BackendAPI/BackendAPI";

export const siteLoadMenu = (languaje) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await apiService.GetMenu({
      urlParams: {
        languaje: LANGUAJE_MAPS[languaje],
      },
    });

    const responseLinks = await apiService.GetUserfulLinks({
      urlParams: {
        languaje: LANGUAJE_MAPS[languaje],
      },
    });

    let menu = buildMenu(response?.data);
    await dispatch(
      loadMenu({
        languaje: languaje,
        menu: menu,
        userfulLinks: responseLinks?.data
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(failLoaded());
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

function buildMenu(options) {
  let menuOptions = options
    ?.filter((option) => option?.parent_target_id === "")
    ?.map((option) => {
      return {
        ...option,
        field_url: option?.field_url?.replace("/api/glifos", ""),
      };
    });

  let childs = options?.filter((option) => option?.parent_target_id !== "");

  childs.forEach((child) => {
    child = {
      ...child,
      field_url: child?.field_url?.replace("/api/glifos", ""),
    };
    let parent = menuOptions?.filter(
      (element) => element?.tid === child?.parent_target_id
    )?.[0];
    if (parent) {
      if (!parent?.childs) {
        parent.childs = [];
      }
      parent.childs.push(child);
    }
  });
  return menuOptions;
}
