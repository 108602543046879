import React, { Suspense, useEffect, useState } from "react";
import { importComponent } from "common/loaders/importComponent";
import { useNodeMetadata } from "modules/website/hooks/useNodeMetadata";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import styles from "./AliasNodeView.module.scss";

export default function AliasNodeView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [importedComponent, setImportedComponent] = useState(null);
  const [componentData, setComponentData] = useState(null);

  // const site = useSelector((state) => state.site); // TODO traducción
  const { isLoading, isError, payload, error } = useNodeMetadata({
    nid: id,
  });

  useEffect(() => {
    const loadComponent = async () => {
      if (isLoading || !payload) return;
      // Importa el módulo dinámicamente basándote en la URL
      let componentUri = null;
      let data = null;
      if (payload?.type === "ubumuntu_lesson") {
        componentUri = "modules/website/Components/Lesson/Lesson";
        data = payload?.metadata;
      }
      if (payload?.type === "ubumuntu_model_lesson_plan") {
        componentUri = "modules/website/Components/LessonPlan/LessonPlan";
        data = payload;
      }
      if (payload?.type === "ubumuntu_archive") {
        componentUri =
          "modules/website/Components/UbumuntuArchive/UbumuntuArchive";
        data = payload;
      }
      if (payload?.type === "ubumuntu_rich_media") {
        componentUri =
          "modules/website/Components/UbumuntuRichMedia/UbumuntuRichMedia";
        data = payload;
      }
      if (!componentUri) {
        console.warn("Component URL not found in ComponentMap");
        navigate("/");
      }

      setImportedComponent(componentUri);
      setComponentData(data);
    };

    if (importedComponent === null) {
      loadComponent();
    }
  }, [payload, isLoading, importedComponent]);

  const LazyComponent =
    importedComponent !== null ? importComponent(importedComponent) : null;

  return (
    <Container
      className={`sm-layout-wrapper scrollSection ${styles.bgGray}`}
      fluid
    >
      {isError && (
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {LazyComponent !== null && !isError && (
        <Suspense fallback={<LoadingBox />}>
          <LazyComponent data={componentData} />
        </Suspense>
      )}
    </Container>
  );
}
