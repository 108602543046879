import React from "react";
import { motion } from "framer-motion";
import { FaArrowDown } from "react-icons/fa6";

const AnimatedButton = () => {
  const scrollToElement = () => {
    document
      .getElementById("tollkitElements")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: [100, 100, 0, 0], opacity: [0, 0, 1, 1, 0] }}
        transition={{
          y: { times: [0, 0.55, 0.6, 1], duration: 11, repeat: Infinity }, // Alinea con la animación de 'opacity'
          opacity: {
            times: [0, 0.75, 0.8, 0.9, 0.93], // Sincroniza los tiempos con la animación de 'y'
            duration: 11,
            repeat: Infinity,
          },
        }}
        style={{
          color: "#fff",
          fontSize: "3rem",
          cursor: "pointer",
        }}
        onClick={scrollToElement}
      >
        <FaArrowDown />
      </motion.div>
    </div>
  );
};

export default AnimatedButton;
