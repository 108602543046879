import React, { useState } from "react";
import Topbar from "../Topbar/Topbar";
import SideMenu from "../SideMenu/SideMenu";

export default function SideMenuTopBar(props) {
  const { variant } = props;
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const activeOffCanvas = () => {
    setOffcanvasOpen(true);
  };
  const handleCloseOffCanvas = () => {
    setOffcanvasOpen(false);
  };

  return (
    <>
      <Topbar variant={variant} activeOffCanvas={activeOffCanvas} />
      <SideMenu
        variant={variant}
        handleCloseOffCanvas={handleCloseOffCanvas}
        offcanvasOpen={offcanvasOpen}
      />
    </>
  );
}
