import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ToolKitElement from "./ToolKitElement/ToolKitElement";
import AboutSection from "./AboutSection/AboutSection";
import Involved from "./Involved/Involved";
import StoriesSlider from "./StoriesSlider/StoriesSlider";
import AnimatedText from "Site/components/AnimatedText/AnimatedText";
import AnimatedButton from "Site/components/AnimatedBtn/AnimatedBtn";
import AnimatedPlatform from "Site/components/AnimatedPlatform/AnimatedPlatform";
import styles from "./HomePage.module.scss";
import { useNodePage } from "modules/website/hooks/useNodePage";
import { LANGUAJE_MAPS } from "services/redux/site/SiteSlice";
import { useSelector } from "react-redux";
import { extractSimpleNodeValue } from "common/helpers/DataFormat";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import GlifosRichMediaPlayer from "modules/website/Components/GlifosRichMediaPlayer/GlifosRichMediaPlayer";
import GlifosRichMediaProvider from "modules/website/context/GlifosRichMediaProvider/GlifosRichMediaProvider";

const HomePage = () => {
  const site = useSelector((state) => state.site);
  const { isLoading, isError, payload, error } = useNodePage({
    nid: 303,
    languaje: LANGUAJE_MAPS[site?.languaje],
  });
  return (
    <>
      {isLoading && <LoadingBox />}
      {isError && <p>{error}</p>}
      {!isLoading && !isError && (
        <Container id="wrapper" className={`sm-layout-wrapper g-0`} fluid>
          <Row
            className={`g-0 d-flex align-items-center justify-content-center bg-dark scrollSection ${styles.container}`}
          >
            <div className="col-10 d-block">
              <div className="text-center text-white">
                <AnimatedText text={extractSimpleNodeValue(payload, "title")} />
              </div>
              <div className="mt-3">
                <AnimatedPlatform
                  text={extractSimpleNodeValue(payload, "field_mp_subtitle")}
                />
              </div>
              <div className="d-flex justify-content-center">
                <AnimatedButton />
              </div>
            </div>
          </Row>
          <Row
            className={`${styles.homeSection} scrollSection bg-white g-0 px-lg-4 px-md-2`}
            id="tollkitElements"
          >
            {payload?.field_mp_toolkits_list?.map((toolkit, idx) => {
              return (
                <div
                  className={`col-12 col-xl-3 px-2`}
                  key={idx}
                  style={{ marginTop: "5rem" }}
                >
                  <ToolKitElement {...toolkit} />
                </div>
              );
            })}
          </Row>
          <Row
            className={`${styles.about} ${styles.homeSection} g-0 text-white align-items-center paddingPageSectionX`}
          >
            <div
              className={`col-12 col-xl-6 ${styles.player} d-flex justify-content-center`}
            >
              <Container fluid>
                <Row>
                  <Col>
                    <GlifosRichMediaProvider>
                      <GlifosRichMediaPlayer
                        src={`https://streaming.ubumuntu.rw/vod/20190723_0006/stream.mpd`}
                      />
                    </GlifosRichMediaProvider>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className={`col-12 col-xl-6`}>
              <AboutSection payload={payload} />
            </div>
          </Row>
          <Row
            className={`${styles.stories} ${styles.homeSection} g-0 d-flex align-items-center justify-content-center text-white`}
          >
            <StoriesSlider payload={payload} />
          </Row>
          <Row
            className={`${styles.homeSection} bg-white g-0 align-items-center px-lg-4 px-md-2`}
          >
            <Involved />
          </Row>
        </Container>
      )}
    </>
  );
};

export default HomePage;
