import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import DefaultRoutes from "./DefaultRoutes/DefaultRoutes";

var AppRoutes = [];
AppRoutes = AppRoutes.concat(DefaultRoutes);

const router = createBrowserRouter(AppRoutes);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
