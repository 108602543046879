import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { gT } from "translate/translator";

export default function SearchBar() {
  const site = useSelector((state) => state.site);
  return (
    <Form className={`d-flex`}>
      <InputGroup>
        <Form.Control
          type="search"
          placeholder={gT(site.languaje, "searchLabel")}
          aria-label={gT(site.languaje, "searchLabel")}
        />
        <InputGroup.Text id="basic-addon1">
          <FaSearch />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}
