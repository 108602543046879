import React from "react";
import styles from "./Involved.module.scss";
import logo from "assets/img/logo_end.png";
import { Button } from "react-bootstrap";
import { FaArrowRight, FaYoutube } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { gT } from "translate/translator";
import { useSelector } from "react-redux";

export default function Involved() {
  const site = useSelector((state) => state.site);

  return (
    <>
      <div className={`${styles.inv_logo} col-12 col-lg-6 d-flex mb-4`}>
        <img src={logo} className={`${styles.inv_img}`} alt="involved" />
      </div>
      <div className={`col-12 col-lg-6`}>
        <div className={`${styles.inv_messages} fill_md_btn`}>
          <h2>{gT(site.languaje, "getInvolved")}</h2>
          <p>{gT(site.languaje, "involvedText")}</p>
          <Link
            to="https://ubumuntu.rw/glifosmassivemail/signup"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="warning" className="text-white text-uppercase">
              <FaArrowRight /> {gT(site.languaje, "learnmore")}{" "}
            </Button>
          </Link>
          <h2 className="mt-4">{gT(site.languaje, "stayInTouch")}</h2>
          <div className="d-flex">
            <Link
              className={styles.contactIcon}
              to="https://www.youtube.com/user/AegisTrust?themeRefresh=1"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube className="me-2 fs-1" />
            </Link>
            <Link
              className={styles.contactIcon}
              to="https://x.com/Aegis_Trust"
              target="_blank"
              rel="noreferrer"
            >
              <FaXTwitter className="me-2 fs-1" />
            </Link>
            <Link
              className={styles.contactIcon}
              to="https://www.instagram.com/kigali_memorial/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="me-2 fs-1" />
            </Link>
            <Link
              className={styles.contactIcon}
              to="https://www.facebook.com/AegisTrust/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="me-2 fs-1" />
            </Link>
          </div>
          <p>{gT(site.languaje, "stayInTouchText")}</p>
          <Link
            to="https://ubumuntu.rw/glifosmassivemail/signup"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="warning" className="text-white">
              <FaArrowRight /> {gT(site.languaje, "joinNewsletter")}{" "}
            </Button>
          </Link>
          <h2 className="mt-4">{gT(site.languaje, "userFeedbackForm")}</h2>
          <p>{gT(site.languaje, "userFeedbackFormText")}</p>
          <Link
            to="https://forms.office.com/Pages/ResponsePage.aspx?id=GZB5tA47AkOD6VQOkCtQiHy8yQwzz7dIg9dwwPkH5uBUMVlTU1dFWFFGNUVSNko5VUpDOUdZUDM1Ui4u"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="warning" className="text-white">
              <FaArrowRight /> {gT(site.languaje, "feedBackForm")}{" "}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
