import React from 'react';
import { Container, Row } from 'react-bootstrap';
import partner from "assets/img/belgium-logo.png"
import styles from './Footer.module.scss';
import { useSelector } from 'react-redux';
import { gT } from 'translate/translator';

export default function Footer() {
	const site = useSelector((state) => state.site);

	return (
		<footer className={`${styles.footer}`}>
			<Container fluid>
				<Row className='py-4'>
					<div className='col-12 col-lg-1 d-none d-lg-block'></div>
					<div className={`col-12 col-lg-8 d-md-flex`}>
						<div className={`${styles.mainUserful} d-none d-md-flex`}>{gT(site.languaje, "ulinks")}</div>

						<div className={`d-lg-none d-block`}>
							<div className={`${styles.userful}`}><a href={"https://ubumuntu.rw/terms-conditions" || "#"} target="_blank" rel="noreferrer">{gT(site.languaje, "termscontitios")}</a></div>
							<div className={`${styles.userful}`}><a href={"https://ubumuntu.rw/privacy#1" || "#"} target="_blank" rel="noreferrer">{gT(site.languaje, "privpolicy")}</a></div>
						</div>
						{site?.userfulLinks?.map((link, idx)=>{
							return <div className={`${styles.userful}`} key={idx}><a href={link?.field_url || "#"} target="_blank" rel="noreferrer">{link?.name}</a></div> 
						})}
					</div>

					<div className={`col-12 col-lg-3 justify-content-end d-none d-lg-flex`}>
						<div className={`${styles.userful}`}><a href={"https://ubumuntu.rw/terms-conditions" || "#"} target="_blank" rel="noreferrer">{gT(site.languaje, "termscontitios")}</a></div>
						<div className={`${styles.userful}`}><a href={"https://ubumuntu.rw/privacy#1" || "#"} target="_blank" rel="noreferrer">{gT(site.languaje, "privpolicy")}</a></div>
					</div>
				</Row>
				<Row className={`${styles.endFooter} py-2`}>
					<div className={`col-12 col-lg-8 d-flex align-items-center mb-2 ${styles.endFooterText}`}>
						<span>Copyright 2019 © Aegis Trust. All Rights Reserved. Registered Charity: UK - Aegis Trust, charity no. 1082856. US - Aegis America Inc. EIN 31-1769192, Rwanda - Aegis Rwanda</span>
					</div>
					<div className='col-12 col-lg-2 mb-2 '>
						<img src={partner} alt='partner' className={`${styles.partner}`}/>
					</div>
					<div className={`col-12 col-lg-2 d-flex align-items-center ${styles.endFooterText}`}>
						<span>This Platform is generously funded by The Kingdom of Belgium</span>
					</div>
				</Row>
			</Container>
		</footer>
	);
}
