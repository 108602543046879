import React from "react";
// import { Card } from "react-bootstrap";
import "./StorieCard.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { gT } from "translate/translator";
import { useSelector } from "react-redux";

export default function StorieCard(props) {
  const { content, title, image, url } = props;
  const site = useSelector((state) => state.site);

  return (
    <div className="singleCard p-2">
      <div className="p-2 bg-white">
        <Link to={url} target="_blank" rel="noreferrer">
          <img src={image} alt={title} className="imgCard" />
        </Link>
      </div>
      <br />
      <h4 className="mt-4 mb-4 text-center">{title}</h4>
      <div className="text_justify mb-4">{content}</div>
      <div className="d-flex justify-content-center fill_md_btn flex_sm_column align_items_sm_start">
        <Link to={url} target="_blank" rel="noreferrer">
          <Button variant="success" className="text-white">
            <FaArrowRight /> {gT(site.languaje, "learnmore")}
          </Button>
        </Link>
      </div>
    </div>
  );
}
