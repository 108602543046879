import React from "react";
import { motion } from "framer-motion";

const AnimatedLetter = (props) => {
  const { index, letter, split } = props;
  return (
    <>
      {split === true && <br />}
      <motion.span
        custom={index}
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0 }}
        variants={{
          hidden: { opacity: 0, scale: 4, x: -10 }, // Empieza grande y a la izquierda
          visible: (i) => ({
            opacity: 1,
            scale: 1,
            x: 0,
            transition: {
              type: "spring",
              stiffness: 90,
              damping: 17,
              delay: index * 0.077 + 1,
            },
          }),
        }}
        style={{ display: "inline-block", marginRight: "0.05em" }}
      >
        {letter === " " ? "\u00A0" : letter}
      </motion.span>
    </>
  );
};

export default AnimatedLetter;
