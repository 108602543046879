import React, { useCallback, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "./styles.css";
import { stories } from "./Stories";
import StorieCard from "./StorieCard/StorieCard";
import {
  IoArrowForwardCircleOutline,
  IoArrowBackCircleOutline,
} from "react-icons/io5";
import { extractSimpleNodeValue } from "common/helpers/DataFormat";

function StoriesSlider(props) {
  const { payload } = props;
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="row">
      <h1 className="d-flex align-items-center justify-content-center mb-4">
        {extractSimpleNodeValue(payload, "field_ubumuntu_stories_label")}
      </h1>
      <div className="col px-4 mt-4 contenedorSwiper">
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 70,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 70,
            },
            1224: {
              slidesPerView: 3,
              spaceBetween: 70,
            },
          }}
          centeredSlides={true}
          modules={[Navigation, EffectCoverflow]}
          className="mySwiper"
          navigation={false}
          // efects
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
        >
          {stories?.map((storie, idx) => {
            return (
              <SwiperSlide key={idx}>
                <StorieCard {...storie} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="leftBtn">
          <IoArrowBackCircleOutline
            style={{ fontSize: "4rem" }}
            onClick={handlePrev}
            variant="outline-light"
          />
        </div>
        <div className="rigthBtn">
          <IoArrowForwardCircleOutline
            style={{ fontSize: "4rem" }}
            onClick={handleNext}
            variant="outline-light"
          />
        </div>
      </div>
    </div>
  );
}

export default StoriesSlider;
